<template>
  <div id="app">
    <el-menu
      style="height: 60px;"
      class="el-menu-demo"
      mode="horizontal"
      :default-active="activeIndex"
      @select="handleSelect"
    >
      <el-menu-item index="home" @click="navigateTo('/')">{{ $t('menu.home') }}</el-menu-item>
      <el-menu-item index="csgoItems" @click="navigateTo('/csgo-items')">{{ $t('menu.csgoItems') }}</el-menu-item>
    </el-menu>
    <router-view></router-view>

  </div>
</template>

<script>
export default {
  data () {
    return {
      activeIndex: 'home'
    }
  },
  methods: {
    handleSelect (key, keyPath) {
      this.activeIndex = key
    },
    navigateTo (route) {
      if (this.$route.path !== route) {
        this.$router.push(route)
      }
    }
  },
  mounted () {
  },
  beforeDestroy () {
  }
}
</script>

<style scoped>
#app {
  height: 100vh;
  background-color: #f9f9f9;
}

</style>
