// src/i18n.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: {
    menu: {
      home: '首页',
      csgoItems: 'CSGO道具',
      pricing: '价格',
      contact: '联系'
    },
    hero: {
      title: '欢迎来到CSGO战术面板',
      subtitle: '全新的方式学习CSGO道具',
      button: '开始使用'
    },
    footer: '© 2024 Coolcr. 保留所有权利。'
  },
  zh: {
    menu: {
      home: '首页',
      csgoItems: 'CSGO道具',
      pricing: '价格',
      contact: '联系'
    },
    hero: {
      title: '欢迎来到CSGO战术面板',
      subtitle: '全新的方式学习CSGO道具',
      button: '开始使用'
    },
    footer: '© 2024 Coolcr. 保留所有权利。'
  }
}

const i18n = new VueI18n({
  locale: 'zh', // 默认语言
  messages
})

export default i18n
