<template>
  <div class="home">
    <h1>{{ $t('hero.title') }}</h1>
    <p>{{ $t('hero.subtitle') }}</p>
    <el-button type="primary" size="large">{{ $t('hero.button') }}</el-button>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>
.home {
  padding: 100px 0;
  background: #f5f5f5;
  text-align: center;
}

.home h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.home p {
  font-size: 24px;
  margin-bottom: 40px;
}
</style>
