import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import 'leaflet/dist/leaflet.css'

// 引入openlayers 样式
import 'ol/ol.css'
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
